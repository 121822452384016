import { Route, Switch, Link } from "react-router-dom";
import "./App.less";
import { useAuth0 } from "@auth0/auth0-react";
import AdminPage from "./pages/admin";
import HomePage from "./pages/home";
import { useUser } from "./lib/hooks/use-user";
import {
  Avatar,
  Button,
  Drawer,
  Grid,
  Layout,
  Menu,
  MenuProps,
  Typography,
} from "antd";
import { ReactComponent as Logo } from "./logo.svg";
import styled from "styled-components";
import { MenuOutlined } from "@ant-design/icons";
// import singleSignOut from './auth/singleSignOut';
import SystemPage from "./pages/system";
import { useState } from "react";
import DashboardPage from "./pages/Dashboard";
import { useSiemensAuth0Logout } from "./lib/hooks/useSiemensAuth0Logout";

const { Header, Footer, Content } = Layout;
const MyLayout = styled(Layout)`
  /* background: #fff; */
`;

const AvatarLink = styled.span`
  height: 64px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 12px;
  &:hover {
    background: rgba(0, 0, 0, 0.025);
  }
`;
const GlobalHeader = styled(Header)`
  padding: 0px;
  height: 64px;
  line-height: 64px;
  width: 100%;
  z-index: 19;
  background: #fff;
  box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
`;
interface GlobalNavProps {
  readonly mobile?: boolean;
}
const GlobalNav = styled.div<GlobalNavProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props.mobile ? "space-between" : "flex-start"};
  height: 100%;
  padding: 0;
`;
const LogoContainer = styled.div`
  height: 64px;
  padding: 12px 16px;
  border: 0;
  border-bottom: 1px solid #f0f0f0;
  box-shadow: none;
`;

const { useBreakpoint } = Grid;

function App() {
  const {
    user,
    isAuthenticated,
    // logout,
  } = useAuth0();

  const { user: userInfo } = useUser();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const showDrawer = () => {
    setDrawerVisible(true);
  };
  const onClose = () => {
    setDrawerVisible(false);
  };
  const screens = useBreakpoint();
  const { singleSignOut } = useSiemensAuth0Logout();

  // const logoutWithRedirect = () =>
  //   logout({
  //     returnTo: window.location.origin,
  //   });
  const menuItems: MenuProps["items"] = [
    {
      label: <Link to="/systems/search">MR System</Link>,
      key: "/systems/search",
    },
    {
      label: <Link to="/dashboard">Dashboard</Link>,
      key: "/dashboard",
    },
  ];

  if (userInfo?.roles?.includes("Administrator")) {
    menuItems.push({
      label: <Link to="/admin/user">Admin</Link>,
      key: "/admin/user",
    });
  }

  if (isAuthenticated && user) {
    menuItems.push({
      label: (
        <a href="mailto:heliumtracker_ssmr.dl@siemens-healthineers.com">
          Support
        </a>
      ),
      key: "/support",
    });
  }

  if (isAuthenticated && user) {
    if (screens.sm) {
      menuItems.push({
        label: (
          <AvatarLink>
            <Avatar
              src={user.picture}
              style={{ marginRight: "8px" }}
            />{" "}
            <span>{`${user.given_name} ${user.family_name}`}</span>
          </AvatarLink>
        ),
        key: "account",
        children: [
          {
            label: (
              <Button
                type="link"
                onClick={() =>
                  singleSignOut({
                    returnTo: window.location.origin,
                  })
                }
              >
                Sign Out
              </Button>
            ),
            key: "signout",
          },
        ],
      });
    } else {
      menuItems.push(
        {
          type: "divider",
        },
        {
          label: (
            <span
              onClick={() =>
                singleSignOut({
                  returnTo: window.location.origin,
                })
              }
            >
              Sign Out
            </span>
          ),
          key: "signout",
        }
      );
    }
  }

  return (
    <>
      <MyLayout>
        <GlobalHeader
          className="no-print"
          style={{ background: "white" }}
        >
          <GlobalNav mobile={!screens.sm}>
            <LogoContainer>
              <Logo style={{ height: "30px" }} />
            </LogoContainer>

            {!screens.sm && (
              <Button
                type="text"
                icon={<MenuOutlined />}
                onClick={showDrawer}
              />
            )}
            {screens.sm && (
              <Menu
                mode="horizontal"
                style={{
                  lineHeight: "64px",
                  width: "100%",
                  justifyContent: "flex-end",
                }}
                selectable={false}
                items={menuItems}
              />
            )}
          </GlobalNav>
        </GlobalHeader>
        <Content>
          <Switch>
            <Route
              path="/"
              exact
              component={HomePage}
            />
            <Route
              path="/systems"
              component={SystemPage}
            />
            <Route
              path="/dashboard"
              component={DashboardPage}
            />
            <Route
              path="/admin"
              component={AdminPage}
            />
          </Switch>
        </Content>
        <Footer style={{ background: "white" }}>
          <div style={{ textAlign: "center", padding: "24px 50px" }}>
            Helium Tracker{" "}
            <Typography.Text type="secondary">
              {process.env.REACT_APP_VERSION}
            </Typography.Text>
          </div>
        </Footer>
      </MyLayout>

      <Drawer
        placement="right"
        closable={false}
        visible={drawerVisible}
        key="left-drawer"
        width={200}
        bodyStyle={{ padding: 0 }}
        onClose={onClose}
      >
        <Menu
          mode="inline"
          style={{ lineHeight: "64px", width: "auto" }}
          selectable={false}
          items={menuItems}
          onClick={onClose}
        ></Menu>
      </Drawer>
    </>
  );
}

export default App;
